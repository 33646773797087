<template>
  <div id="app-container">
    <div class="header">
      <img
        class="logo"
        src="https://img01.yzcdn.cn/upload_files/2022/04/28/FmBSvSUBryDK7xDxHzjrIR40I5ac.png"
        alt=""
      />
      <div class="btns">
        <div class="btn">
          <span>首页</span>
        </div>
        <div class="btn" @click="handleClick">
          <span>登录</span>
        </div>
      </div>
    </div>
    <div class="main">
      <img
        class="main-img"
        src="https://img01.yzcdn.cn/upload_files/2023/10/19/FsWXyOKPum-WA1WQvS_aCnBycMas.png"
      />
    </div>

    <div class="footer">
      <a class="link" href="https://beian.miit.gov.cn" target="_blank"
        >浙ICP备2020040621号-13</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {
    handleClick() {
      window.location.href = "https://www.youzan.com/v4/shop/shop-list#/";
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
#app-container {
  width: 100vw;
  min-height: 100vh;
  text-align: center;
  background-color: #66be74;
  .header {
    background: #fff;
    height: 68px;
    width: 100%;
    padding: 0 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    img {
      width: 103px;
      height: 28px;
      /* max-width: 1920px; */
    }
    .btns {
      display: flex;
      .btn {
        cursor: pointer;
      }
      .btn + .btn {
        margin-left: 16px;
      }
      span {
        color: #00c261;
        line-height: 20px;
        font-size: 14px;
      }
    }
  }
  .main {
    width: 100vw;
    text-align: center;
    &-img {
      /* max-width: 1440px; */
      width: 100%;
    }
  }
  .footer {
    width: 100vw;
    background: #000;
    color: #fff;
    .link {
      font-size: 12px;
      color: #969799;
      text-decoration: none;
      line-height: 22px;

      &:hover {
        color: #fff;
      }
    }
  }
}
</style>
